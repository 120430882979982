import { RootState } from '@/store';
import { Dispatch } from '@reduxjs/toolkit';
import {
  getReportFailure,
  getReportRequest,
  getReportSuccess,
} from '@/store/reducers/report';
import { fetchData } from './fetchData';

export const getStayReport =
  (auid: string) => (dispatch: Dispatch, getState: () => RootState) => {
    if (!auid) {
      return;
    }
    return fetchData({
      endpoint: `appointment/${auid}/consumer/stay-report`,
      actions: [getReportRequest, getReportSuccess, getReportFailure],
    })?.(dispatch, getState);
  };

export const getStayUpdate =
  ({ auid, date }: { auid: string; date: string }) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    if (!auid || !date) {
      return;
    }
    return fetchData({
      endpoint: `appointment/${auid}/consumer/stay-update?date=${date}`,
      actions: [getReportRequest, getReportSuccess, getReportFailure],
    })?.(dispatch, getState);
  };
