import { Helmet } from 'react-helmet-async';
import Button from '@/atoms/button';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { getKennelDescription, getKennelTitle, isNotEmptyArray } from '@/util';
import { editForm } from '@/store/reducers/booking-form';
import { Service } from '@/types/composite';
import SidePanel from '../components/side-panel';
import AppointmentDetails from '../components/appointment-details';
import MainPanel from '../components/main-panel';
import useBookingFlow from '../hooks/use-booking-flow';
import SelectableItem from '../components/selectable-item';
import '../booking-flow.scss';

export default function BookingKennelSelect() {
  const dispatch = useAppDispatch();
  const { availableKennelUpgrades } = useAppSelector(
    (state) => state.bookingFlow,
  );
  const { service: selectedService } = useAppSelector(
    (state) => state.bookingForm,
  );
  const selectedServicePrice = selectedService?.price?.amount || 0;
  const { processNextStep } = useBookingFlow();

  const onSelectService = (service: Service) => {
    dispatch(editForm({ key: 'service', value: service }));
  };

  const onNext = async () => {
    await processNextStep();
  };

  return (
    <>
      <Helmet title="Select kennel for booking | PawCare" />
      <div className="kennel-select flex-row w-100">
        <SidePanel>
          <AppointmentDetails />
        </SidePanel>
        <MainPanel>
          <div className="flex-column gap-32">
            <h2 className="f26 bold">Select kennel</h2>
            {isNotEmptyArray(availableKennelUpgrades) ? (
              <>
                <div className="flex-column gap-12">
                  {availableKennelUpgrades.map((service) => {
                    const { kennel, price, uuid } = service;
                    const isCurrentService = selectedService?.uuid === uuid;
                    const servicePrice = price?.amount || 0;
                    const priceDifference = `${servicePrice >= selectedServicePrice ? '+' : '-'}$${Math.abs(servicePrice - selectedServicePrice).toLocaleString()}`;

                    return (
                      <SelectableItem
                        key={uuid}
                        onClick={() => onSelectService(service)}
                        isActive={isCurrentService}
                        size="large"
                      >
                        <div className="flex-row align-start justify-between gap-32 w-100">
                          <div className="flex-column align-start">
                            <h3 className="f16 bold text-black text-left">
                              {getKennelTitle(kennel)}
                            </h3>
                            <p className="f16 fw-400 text-left text-black">
                              {getKennelDescription(kennel)}
                            </p>
                          </div>
                          {!isCurrentService ? (
                            <div className="flex-column align-end flex-shrink-0">
                              <h3 className="f16 bold text-black text-left">
                                {priceDifference}
                              </h3>
                              <p className="f16 fw-400 text-primary-purple">
                                per night
                              </p>
                            </div>
                          ) : null}
                        </div>
                      </SelectableItem>
                    );
                  })}
                </div>
                <Button
                  text="Next"
                  onClick={onNext}
                  size="large"
                  className="desktop-next-btn w-fit"
                  variant="default"
                />
              </>
            ) : (
              <p className="f16 fw-400">No kennels available</p>
            )}
          </div>
        </MainPanel>
        <div className="mobile-next-btn">
          <Button text="Next" onClick={onNext} className="mt-25" />
        </div>
      </div>
    </>
  );
}
