import { Route, Routes } from 'react-router-dom';
import AboutUs from '@/pages/about-us';
import AddAddress from '@/pages/add-address';
import AdditionalPetDetails from '@/pages/additional-pet-details';
import AdminSearch from '@/pages/admin-search';
import AdminSignup from '@/pages/admin-signup';
import Blog from '@/pages/blog';
import ConsumerLandingDeepLink from '@/pages/consumer-landing-deep-link';
import CreatePet from '@/pages/create-pet';
import FinishSignup from '@/pages/finish-signup';
import FinishSignupMFA from '@/pages/finish-signup-mfa';
import Home from '@/pages/home';
import LinkMobileRedirect from '@/pages/link-mobile-redirect';
import Login from '@/pages/login';
import MFA from '@/pages/mfa';
import Search from '@/pages/search';
import Settings from '@/pages/settings';
import Signup from '@/pages/signup';
import NewPassword from '@/pages/new-password';
import NotFound from '@/pages/not-found';
import PetProfile from '@/pages/pet-profile';
import PhoneVerification from '@/pages/phone-verification';
import ResetPassword from '@/pages/reset-password';
import TermsOfService from '@/pages/terms-of-service';
import UploadPetAvatar from '@/pages/upload-pet-avatar';
import UnsubscribePage from '@/pages/unsubscribe';
import LoginMfa from '@/pages/login/mfa';
import CompleteSignup from '@/pages/complete-signup';
import BookingPetSelect from '@/pages/booking-flow/pet-select';
import BookingServiceType from '@/pages/booking-flow/service-type';
import BookingServiceSelect from '@/pages/booking-flow/service-select';
import BookingAddOns from '@/pages/booking-flow/add-ons';
import BookingGroomerSelect from '@/pages/booking-flow/groomer-select';
import BookingDateTime from '@/pages/booking-flow/date-time';
import BookingVaccinations from '@/pages/booking-flow/vaccinations';
import BookingPayment from '@/pages/booking-flow/payment';
import BookingConfirmed from '@/pages/booking-flow/confirmed';
import BookingPetInformation from '@/pages/booking-flow/pet-information';
import BookingKennelSelect from '@/pages/booking-flow/kennel-select';
import BookingBehavior from '@/pages/booking-flow/behavior';
import BookingMedications from '@/pages/booking-flow/medications';
import BookingUserInformation from '@/pages/booking-flow/user-information';
import Report from '@/pages/report';
import ProviderProfile from '@/pages/provider-profile';
import PrivateRoute from './private-route';

const getRoutes = () => (
  <Routes>
    {/* General */}
    <Route path="*" element={<NotFound />} />
    <Route path="link" element={<LinkMobileRedirect />} />
    <Route path="terms" element={<TermsOfService />} />
    <Route path="openapp" element={<ConsumerLandingDeepLink />} />
    <Route path="about" element={<AboutUs />} />
    <Route path="blog" element={<Blog />} />

    {/* Admin */}
    <Route
      path="search-consumer"
      element={
        <PrivateRoute adminCheck>
          <AdminSearch />
        </PrivateRoute>
      }
    />
    <Route path="admin/_signup" element={<AdminSignup />} />

    {/* Home */}
    <Route path="/" element={<Home />} />
    <Route path="/advanced-search" element={<Home />} />

    {/* Regular signup */}
    <Route path="login" element={<Login />} />
    <Route path="login/mfa" element={<LoginMfa />} />
    <Route path="signup" element={<Signup />} />
    <Route path="signup-mfa" element={<MFA />} />
    <Route path="signup/complete" element={<CompleteSignup />} />
    <Route path="signup/address" element={<AddAddress />} />
    <Route path="signup/finish-signup" element={<FinishSignup />}>
      <Route path=":code" element={<FinishSignup />} />
    </Route>
    <Route path="signup/phone-verification" element={<PhoneVerification />} />
    <Route path="signup/mfa" element={<FinishSignupMFA />} />
    <Route
      path="signup/additional-pet-details"
      element={<AdditionalPetDetails />}
    />

    {/* Password reset */}
    <Route path="trigger-password-reset" element={<ResetPassword />} />
    <Route path="password-reset" element={<NewPassword />}>
      <Route path=":code" element={<NewPassword />} />
    </Route>

    {/* Providers */}
    <Route path=":city/:type/:slug" element={<ProviderProfile />} />
    <Route path="pet/groomers/chicago" element={<Search />}>
      <Route path=":neighborhood" element={<Search />} />
      <Route path="type/:providerType" element={<Search />} />
    </Route>

    {/* Create pet */}
    <Route path="create-pet" element={<CreatePet />} />
    <Route path="upload-avatar" element={<UploadPetAvatar />} />
    <Route path="user/:userId/pet" element={<PetProfile />}>
      <Route path=":petId" element={<PetProfile />} />
    </Route>

    {/* Booking flow */}
    <Route path="booking/pet-information" element={<BookingPetInformation />} />
    <Route path="booking/pet-select" element={<BookingPetSelect />} />
    <Route path="booking/service-type" element={<BookingServiceType />} />
    <Route path="booking/service" element={<BookingServiceSelect />} />
    <Route path="booking/kennel" element={<BookingKennelSelect />} />
    <Route path="booking/add-ons" element={<BookingAddOns />} />
    <Route path="booking/groomer" element={<BookingGroomerSelect />} />
    <Route path="booking/date-time" element={<BookingDateTime />} />
    <Route path="booking/behavior" element={<BookingBehavior />} />
    <Route path="booking/medications" element={<BookingMedications />} />
    <Route path="booking/vaccinations" element={<BookingVaccinations />} />
    <Route
      path="booking/user-information"
      element={<BookingUserInformation />}
    />
    <Route path="booking/payment" element={<BookingPayment />} />
    <Route path="booking/confirmation" element={<BookingConfirmed />} />

    {/* Providers */}
    <Route path=":city/:type/:slug" element={<ProviderProfile />} />
    <Route path="pet/groomers/chicago" element={<Search />}>
      <Route path=":neighborhood" element={<Search />} />
      <Route path="type/:providerType" element={<Search />} />
    </Route>

    {/* Create pet */}
    <Route path="create-pet" element={<CreatePet />} />
    <Route path="upload-avatar" element={<UploadPetAvatar />} />
    <Route path="user/:userId/pet" element={<PetProfile />}>
      <Route path=":petId" element={<PetProfile />} />
    </Route>

    {/* Report */}
    <Route path="appointment/:auid/consumer/stay-report" element={<Report />} />
    <Route path="appointment/:auid/consumer/stay-update" element={<Report />} />

    {/* Settings */}
    <Route path="settings" element={<Settings />} />
    <Route path="unsubscribe" element={<UnsubscribePage />} />
  </Routes>
);

export default getRoutes;
